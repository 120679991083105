import React from 'react';
import { Typography, Box, Link as MaterialLink } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MaterialLink component={Link} to="/" color="inherit">
        Dishify
      </MaterialLink>{' '}
      {new Date().getFullYear()}
      {'.'}
      <br></br>
      Made by{' '}
      <MaterialLink href="https://chrisot.com" color="inherit">
        Christopher Ortez
      </MaterialLink>{' '}
      & FuYuan Yan
      <br></br>
      <MaterialLink component={Link} to="/privacypolicy" color="inherit">
        Privacy Policy
      </MaterialLink>
    </Typography>
  );
};
export default Copyright;
