import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Icon,
  IconButton,
  CardActions,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    margin: '10px 0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
}));

const ImageCard = ({ restaurant }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title={restaurant.name} />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Address: {restaurant.formatted_address}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Rating: {restaurant.rating}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Price Level: {restaurant.price_level}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=restaurant&query_place_id=${restaurant.place_id}`}
          style={{ borderRadius: '10px' }}
          aria-label="directions"
        >
          <Icon className="fas fa-map-marked-alt" />
          <Typography style={{ marginLeft: '10px' }} variant="button">
            Directions
          </Typography>
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default ImageCard;
