import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Icon,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LockOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  auth,
  signInWithFacebook,
  signInWithGoogle,
  signInWithTwitter,
} from '../firebase';

import Copyright from './Copyright';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: 'pointer',
  },
  social: {},
}));

const CustomTextField = (props) => (
  <TextField variant="outlined" margin="normal" fullWidth {...props} />
);

function SignIn(props) {
  useEffect(() => {
    document.title = 'Dishify - Sign In';
  });

  const [errorLoggingIn, setErrorLoggingIn] = useState(null);

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <Typography variant="h2" component="h2" gutterBottom>
          Dishify
        </Typography>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {errorLoggingIn ? (
          <Typography variant="body2" color="error">
            {errorLoggingIn}
          </Typography>
        ) : null}
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Email is required'),
            password: Yup.string().required('Password is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              const { user } = auth
                .signInWithEmailAndPassword(values.email, values.password)
                .catch((error) => {
                  setErrorLoggingIn('Email or password is incorrect');
                });

              setSubmitting(false);
            }, 400);
          }}
        >
          <Form className={classes.form}>
            <Field
              name="email"
              type="email"
              label="Email"
              as={CustomTextField}
            />
            <ErrorMessage name="email">
              {(msg) => (
                <Typography variant="caption" color="error">
                  {msg}
                </Typography>
              )}
            </ErrorMessage>
            <Field
              name="password"
              type="password"
              label="Password"
              as={CustomTextField}
            />
            <ErrorMessage name="password">
              {(msg) => (
                <Typography variant="caption" color="error">
                  {msg}
                </Typography>
              )}
            </ErrorMessage>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <div align="center" className={classes.social}>
              <Typography>or sign in with:</Typography>
              <IconButton
                onClick={() =>
                  signInWithGoogle().catch((e) => setErrorLoggingIn(e.message))
                }
                aria-label="google"
              >
                <Icon className="fab fa-google" />
              </IconButton>
              <IconButton
                onClick={() =>
                  signInWithFacebook().catch((e) =>
                    setErrorLoggingIn(e.message)
                  )
                }
                aria-label="facebook"
              >
                <Icon className="fab fa-facebook" />
              </IconButton>
              <IconButton
                onClick={() =>
                  signInWithTwitter().catch((e) => setErrorLoggingIn(e.message))
                }
                aria-label="twitter"
              >
                <Icon className="fab fa-twitter" />
              </IconButton>
            </div>

            <Grid container>
              <Grid item xs>
                <Link
                  className={classes.link}
                  onClick={() => props.setCurrentSide('forgotpassword')}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={classes.link}
                  onClick={() => props.setCurrentSide('signup')}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </Form>
        </Formik>
      </div>
    </Grid>
  );
}

SignIn.propTypes = {
  setCurrentSide: PropTypes.func,
};

export default SignIn;
