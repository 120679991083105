import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, CircularProgress, Box } from '@material-ui/core';
import { Cat } from 'react-kawaii';
import { useTheme } from '@material-ui/core/styles';

import ImageCard from './ImageCard';
import RestaurantCard from './RestaurantCard';
import RecipeCard from './RecipeCard';

const useStyles = makeStyles((theme) => ({
  cardList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  errorImg: {
    width: '200px',
    height: 'auto',
    padding: '5px',
  },
  error: {
    textAlign: 'center',
  },
  footerPexels: {
    marginLeft: '30px',
  },
  loadingCircle: {
    marginTop: '2rem',
    textAlign: 'center',
  },
}));

const CardList = ({
  BACKEND_URL,
  concepts,
  current,
  currentAPI,
  setAPI,
  error,
  setError,
}) => {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState(null);

  const classes = useStyles();
  const theme = useTheme();

  //TODO: Fix useEffect

  // Get the user's position.
  useEffect(() => {
    if (
      navigator.geolocation &&
      !currentAPI &&
      !location &&
      current === 'restaurants'
    ) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const latitude = pos.coords.latitude;
          const longitude = pos.coords.longitude;
          setLocation({ latitude: latitude, longitude: longitude });
        },
        (err) => {
          setLocation('disabled');
        }
      );
    }
  });

  // Send an API call to the backend.
  useEffect(() => {
    if (!currentAPI) {
      setLoading(true);

      if (
        current !== 'restaurants' ||
        (current === 'restaurants' && location)
      ) {
        axios({
          method: 'post',
          url: `${BACKEND_URL}/api/${current}`,
          data: {
            concepts: concepts,
            location: location ? location : null,
          },
        })
          .then((res) => {
            if (res.data) {
              if (res.data.name === 'Error' || !res.data) {
                setError('Unable to receive a response from the API.');
                setLoading(false);
              } else {
                // console.log('res.data', res.data);
                setAPI(res.data);
                setLoading(false);
              }
            }
          })
          .catch((err) => {
            setError('Unable to receive a response from the API.');
            setLoading(false);
          });
      }
    }
  });

  const renderCards = () => {
    switch (current) {
      case 'images':
        if (currentAPI.photos.length === 0) {
          setError('Unable to find images');
          return;
        }
        return currentAPI.photos.map((image) => (
          <ImageCard key={image.id} image={image} />
        ));
      case 'restaurants':
        if (location === 'disabled') {
          setError('Please enable geolocation.');
          return;
        }
        if (currentAPI.results.length === 0) {
          setError('Unable to find restaurants.');
          return;
        }
        return currentAPI.results.map((restaurant) => (
          <RestaurantCard restaurant={restaurant}></RestaurantCard>
        ));
      case 'recipes':
        if (currentAPI.hits.length === 0) {
          setError('Unable to find recipes.');
          return;
        }
        return currentAPI.hits.map((recipeObj) => (
          <RecipeCard recipe={recipeObj.recipe}></RecipeCard>
        ));
      default:
        setError('Unable to render cards');
    }
  };

  if (loading) {
    return (
      <Box className={classes.loadingCircle}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <div className={!error ? classes.cardList : classes.error}>
        {error ? (
          <>
            <Typography
              style={{ margin: '20px 0px', fontWeight: '700' }}
              variant="body1"
              color="error"
            >
              Oops... {JSON.stringify(error)}
            </Typography>
            <Cat size={100} mood="ko" color={theme.palette.primary.light} />
          </>
        ) : currentAPI && !error ? (
          renderCards()
        ) : null}
      </div>
      {current === 'images' ? (
        <Typography
          className={classes.footerPexels}
          color="textSecondary"
          variant="caption"
          align="center"
        >
          Images Provided by <Link href="https://www.pexels.com/">Pexels</Link>
        </Typography>
      ) : null}
    </>
  );
};

export default CardList;
