import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardHeader, ButtonBase } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    margin: '10px 0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    transition: '2s',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
}));

const ImageCard = ({ image }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        align="center"
        subheaderTypographyProps={{ color: 'textPrimary' }}
        subheader={image.photographer}
      />

      <a target="_blank" rel="noopener noreferrer" href={image.url}>
        <CardMedia className={classes.media} image={image.src.medium} />
      </a>
    </Card>
  );
};

export default ImageCard;
