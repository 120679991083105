import React from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cat } from 'react-kawaii';
import { useTheme } from '@material-ui/core/styles';
import { useAuthState } from 'react-firebase-hooks/auth';
import { deleteUserProfileDocument, auth } from '../firebase';
import { withRouter } from 'react-router-dom';

import PageLayout from '../components/PageLayout';

const useStyles = makeStyles((theme) => ({
  modalOverlay: {
    transition: '2s ease-in',
    zIndex: '1000',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  title: {
    marginBottom: '2rem',
  },
  cat: {
    position: 'absolute',
    bottom: '59px',
    marginTop: '100px',
    left: 'calc(50vw - 36px)',
  },
  options: {
    display: 'flex',
    justifyContent: 'center',
  },
  option: {
    marginTop: '4rem',
    display: 'flex',
    alignContent: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  deletePrompt: {
    right: '10%',
    left: '10%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    position: 'absolute',
    zIndex: '1010',
  },
  paper: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  confirmationBtns: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      margin: '1rem',
      padding: '.8rem',
    },
  },
}));

const Account = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [deletePromptToggle, setDeletePromptToggle] = React.useState(false);
  const [user, loading] = useAuthState(auth);

  return (
    <PageLayout>
      <Paper
        elevation="0"
        style={{
          paddingTop: '2rem',
          paddingBottom: '0.4rem',
          marginBottom: '1rem',
        }}
      >
        <Typography align="center" className={classes.title} variant="h3">
          My Account
        </Typography>
      </Paper>

      {deletePromptToggle ? (
        <>
          {' '}
          <Box className={classes.modalOverlay}></Box>
          <Box className={classes.deletePrompt}>
            <Paper className={classes.paper}>
              <Typography variant="h5">
                Are you sure you want to delete your account? You cannot undo
                this.
              </Typography>
              <Box className={classes.confirmationBtns}>
                <Button
                  onClick={() => deleteUserProfileDocument(user)}
                  variant="contained"
                  color="secondary"
                >
                  Yes, delete my account!
                </Button>
                <Button
                  onClick={() => setDeletePromptToggle(false)}
                  variant="contained"
                  color="primary"
                >
                  No, I changed my mind.
                </Button>
              </Box>
            </Paper>
          </Box>{' '}
        </>
      ) : null}

      <Typography align="center">Currently signed as {user.email}</Typography>
      <Box className={classes.options}>
        <Box className={classes.option}>
          <Typography variant="h6" style={{ display: 'inline' }}>
            Delete your account
          </Typography>
          <Button
            onClick={() => {
              setDeletePromptToggle(true);
            }}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        </Box>
      </Box>

      <Box className={classes.cat}>
        <Cat
          size={100}
          mood={deletePromptToggle ? 'sad' : 'blissful'}
          color={theme.palette.primary.light}
        />
      </Box>
    </PageLayout>
  );
};

export default withRouter(Account);
