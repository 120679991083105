import React, { useState } from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import ForgotPassword from '../components/ForgotPassword';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://images.unsplash.com/photo-1586511926652-02f61795e70d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  caption: {
    backgroundColor: 'rgba(0,0,0, 0.5)',
    padding: '0.5rem',
    color: 'white',
    position: 'absolute',
    bottom: '2%',
    left: '2%',
  },
}));

const LoginAndRegister = () => {
  const [currentSide, setCurrentSide] = useState('signin');

  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const renderSide = (() => {
    switch (currentSide) {
      case 'signin':
        return <SignIn setCurrentSide={setCurrentSide} />;
      case 'signup':
        return <SignUp setCurrentSide={setCurrentSide} />;
      case 'forgotpassword':
        return <ForgotPassword setCurrentSide={setCurrentSide} />;
      default:
        return <SignIn setCurrentSide={setCurrentSide} />;
    }
  })();

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item sm={4} md={7} className={classes.image}>
        <Box
          style={{
            visibility: !matches ? 'hidden' : 'visible',
          }}
          className={classes.caption}
        >
          <Typography sm={4} style={{ fontSize: '0.6rem' }} variant="caption">
            Photo by Fathul Abrar on Unsplash
          </Typography>
        </Box>
      </Grid>

      {renderSide}
    </Grid>
  );
};

export default LoginAndRegister;
