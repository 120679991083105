import React from 'react';
import Navbar from './Navbar';
import Copyright from './Copyright';
import { Container, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '2.5rem',
    paddingBottom: '6rem',
  },
  footer: {
    bottom: '0',
    position: 'absolute',
    width: '100%',
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    minHeight: '100%',
  },
  pagecontainer: {
    position: 'relative',
    minHeight: '100vh',
  },
}));

const PageLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pagecontainer}>
        <Navbar />
        <Container className={classes.content}>{children}</Container>
        <Paper className={classes.footer} elevation={0}>
          <Copyright />
        </Paper>
      </div>
    </>
  );
};

export default PageLayout;
