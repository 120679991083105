import React, { Component } from 'react';

//Uppy imports
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react/';
import Webcam from '@uppy/webcam';
import XHRUpload from '@uppy/xhr-upload';

//Uppy css imports
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.uppy = Uppy({
      debug: false,
      autoProceed: true,
      restrictions: {
        maxFileSize: 5000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
    })
      .use(Webcam, {
        modes: ['picture'],
        mirror: false,
        facingMode: 'environment',
      })
      .use(XHRUpload, {
        formData: true,
        endpoint: `${this.props.BACKEND_URL}/api/upload`,
      })
      .on('upload-success', (file, response) => {
        let data = response.body;
        this.props.setOpen(false);
        this.props.setConcepts(data);
        this.uppy.close();
      })
      .on('upload-error', (file, error, response) => {
        this.props.setOpen(false);
        this.uppy.close();
      });
  }

  componentWillUnmount() {
    this.props.setOpen(false);
    this.uppy.close();
  }

  render() {
    return (
      <DashboardModal
        open={this.props.open}
        note="Please only upload one image. (5 MB Maximum filesize)."
        plugins={['Webcam']}
        uppy={this.uppy}
        onRequestClose={() => this.props.setOpen(false)}
      />
    );
  }
}

export default Upload;
