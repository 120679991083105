import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth } from '../firebase';

import Copyright from './Copyright';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    cursor: 'pointer',
  },
  social: {},
}));

const CustomTextField = (props) => (
  <TextField variant="outlined" margin="normal" fullWidth {...props} />
);

function ForgotPassword(props) {
  useEffect(() => {
    document.title = 'Dishify - Reset Your Password';
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <Typography variant="h2" component="h2" gutterBottom>
          Dishify
        </Typography>
        <Avatar className={classes.avatar}>
          <Help />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>

        <Typography variant="body2" color={error ? 'error' : 'textPrimary'}>
          {message}
        </Typography>

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Email is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              auth
                .sendPasswordResetEmail(values.email)
                .then(function () {
                  setMessage(
                    'An email has been sent to the provided email address.'
                  );
                })
                .catch(function (error) {
                  setError(true);
                  setMessage(error.message);
                });

              setSubmitting(false);
            }, 400);
          }}
        >
          <Form className={classes.form}>
            <Field
              name="email"
              type="email"
              label="Email"
              as={CustomTextField}
            />
            <ErrorMessage name="email">
              {(msg) => (
                <Typography variant="caption" color="error">
                  {msg}
                </Typography>
              )}
            </ErrorMessage>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Reset my password
            </Button>

            <Grid container>
              <Grid item xs>
                <Link
                  onClick={() => props.setCurrentSide('signin')}
                  variant="body2"
                  className={classes.link}
                >
                  {'Have an account? Sign In'}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={classes.link}
                  onClick={() => props.setCurrentSide('signup')}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </Form>
        </Formik>
      </div>
    </Grid>
  );
}

ForgotPassword.propTypes = {
  setCurrentSide: PropTypes.func,
};

export default ForgotPassword;
