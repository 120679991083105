import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  IconButton,
  Icon,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cat } from 'react-kawaii';
import { useTheme } from '@material-ui/core/styles';

import CardList from '../components/CardList';
import ImageUploader from '../components/ImageUploader';
import PageLayout from '../components/PageLayout';

const BACKEND_URL = 'https://dishify-backend.herokuapp.com';
// const BACKEND_URL = 'http://localhost:3001';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '30px',
  },
  cardList: {
    display: 'flex',
  },
  uploaderContainer: {
    textAlign: 'center',
  },
  errorImg: {
    width: '200px',
    height: 'auto',
    padding: '5px',
  },
  error: {
    textAlign: 'center',
  },
  btnContainer: {
    margin: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& button': {
      margin: '0.5rem 1rem',
    },
  },
  btnReuploadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 2.3rem',
  },
  cat: {
    position: 'absolute',
    bottom: '59px',
    marginTop: '100px',
    left: 'calc(50vw - 36px)',
  },
}));

const Upload = (props) => {
  const [concepts, setConcepts] = useState('');
  const [current, setCurrent] = useState('images');
  // const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const [recipesAPI, setRecipesAPI] = useState(null);
  const [restaurantsAPI, setRestaurantsAPI] = useState(null);
  const [imagesAPI, setImagesAPI] = useState(null);

  const [recipesError, setRecipesError] = useState(null);
  const [restaurantsError, setRestaurantsError] = useState(null);
  const [imagesError, setImagesError] = useState(null);

  const theme = useTheme();
  const classes = useStyles();

  const renderCardList = () => {
    let currentAPI, setAPI, error, setError;
    switch (current) {
      case 'images':
        currentAPI = imagesAPI;
        setAPI = setImagesAPI;
        error = imagesError;
        setError = setImagesError;
        break;
      case 'recipes':
        currentAPI = recipesAPI;
        setAPI = setRecipesAPI;
        error = recipesError;
        setError = setRecipesError;
        break;
      case 'restaurants':
        currentAPI = restaurantsAPI;
        setAPI = setRestaurantsAPI;
        error = restaurantsError;
        setError = setRestaurantsError;
        break;
      default:
        return (
          <Typography variant="caption" color="Error">
            Error rendering cardlist
          </Typography>
        );
    }
    return (
      <CardList
        BACKEND_URL={BACKEND_URL}
        concepts={concepts}
        current={current}
        currentAPI={currentAPI}
        setAPI={setAPI}
        error={error}
        setError={setError}
      ></CardList>
    );
  };

  const renderOptions = () => (
    <div className={classes.btnContainer}>
      <Button
        color="primary"
        variant="contained"
        disabled={current === 'recipes' ? true : false}
        onClick={() => setCurrent('recipes')}
      >
        Recipes
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={current === 'restaurants' ? true : false}
        onClick={() => setCurrent('restaurants')}
      >
        Restaurants
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={current === 'images' ? true : false}
        onClick={() => setCurrent('images')}
      >
        Images
      </Button>
    </div>
  );

  return (
    <PageLayout>
      <Paper
        elevation={0}
        style={{
          paddingTop: '2rem',
          paddingBottom: '0.4rem',
          marginBottom: '1rem',
        }}
      >
        <Typography align="center" className={classes.title} variant="h3">
          Upload
        </Typography>
      </Paper>
      {concepts ? (
        <>
          <Box className={classes.btnReuploadContainer}>
            <Button
              onClick={() => {
                setConcepts('');
                setRecipesAPI(null);
                setImagesAPI(null);
                setRestaurantsAPI(null);
              }}
              color="secondary"
              variant="contained"
            >
              Re-upload
            </Button>
          </Box>

          {renderOptions()}
          {renderCardList()}
        </>
      ) : (
        <>
          <Box className={classes.uploaderContainer}>
            <IconButton
              style={{ marginTop: '40px' }}
              color="secondary"
              onClick={() => setOpen(true)}
            >
              <Icon
                style={{ fontSize: 60 }}
                className="fas fa-arrow-circle-up"
              />
            </IconButton>

            <ImageUploader
              open={open}
              BACKEND_URL={BACKEND_URL}
              setConcepts={setConcepts}
              setOpen={setOpen}
            />
          </Box>
          <Box className={classes.cat}>
            <Cat
              size={100}
              mood="excited"
              color={theme.palette.primary.light}
            />
          </Box>
        </>
      )}
    </PageLayout>
  );
};

export default Upload;
