import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Icon,
  IconButton,
  Typography,
  InputBase,
  Badge,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { auth } from '../firebase';
import { FilterContext } from '../contexts/FilterContext.js';
import { useAuthState } from 'react-firebase-hooks/auth';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  label: {
    marginLeft: '0.5rem',
    fontWeight: 'bold',
  },
}));

function Navbar({ history }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { filter, changeFilter } = useContext(FilterContext);
  const [user, loading] = useAuthState(auth);

  const renderSearchBar = (() => {
    if (history.location.pathname === '/favorites') return true;
    return false;
  })();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  //Desktop Menu Layout
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          history.push('/account');
        }}
      >
        My Account
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          auth.signOut();
        }}
      >
        Sign Out
      </MenuItem>
    </Menu>
  );

  //Mobile Menu Layout
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* Upload */}
      <MenuItem onClick={() => history.push('/upload')}>
        <IconButton aria-label="upload a photo" color="inherit">
          <Badge color="secondary">
            <Icon className="fas fa-camera" />
          </Badge>
        </IconButton>
        <p>Upload Dish</p>
      </MenuItem>
      {/* Favorites */}
      <MenuItem onClick={() => history.push('/favorites')}>
        <IconButton aria-label="show favorites" color="inherit">
          <Badge color="secondary">
            <Icon style={{ overflow: 'visible' }} className="fas fa-heart" />
          </Badge>
        </IconButton>
        <p>Favorites</p>
      </MenuItem>
      {/* Account */}
      <MenuItem onClick={() => history.push('/account')}>
        <IconButton aria-label="account of current user" color="inherit">
          <Icon className="fas fa-user-circle" />
        </IconButton>
        <p>My Account</p>
      </MenuItem>
      {/* Sign Out */}
      <MenuItem onClick={() => auth.signOut()}>
        <IconButton aria-label="sign out" color="inherit">
          <Icon className="fas fa-sign-out-alt" />
        </IconButton>
        <p>Sign Out</p>
      </MenuItem>
    </Menu>
  );

  if (!user) {
    return (
      <div className={classes.grow}>
        <AppBar position="static">
          <Toolbar>
            <Typography className={classes.title} variant="h6" noWrap>
              Dishify
            </Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* Sign Out */}
              <IconButton
                edge="end"
                onClick={() => history.push('/signin')}
                aria-label="sign out"
                color="inherit"
                style={{ borderRadius: '10px' }}
              >
                <Icon className="fas fa-sign-in-alt" />
                <Typography className={classes.label}>Sign In</Typography>
              </IconButton>
            </div>
            {/* Mobile Hamburger */}
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <Icon className="fas fa-bars" />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>
    );
  }

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Dishify
          </Typography>
          {renderSearchBar ? (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Icon className="fas fa-search" />
              </div>
              <InputBase
                placeholder="Search favorite recipes"
                value={filter}
                onChange={changeFilter}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search favorite recipes' }}
              />
            </div>
          ) : null}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* Upload */}
            <IconButton
              onClick={() => history.push('/upload')}
              aria-label="upload a photo"
              color="inherit"
              style={{ borderRadius: '10px' }}
            >
              <Badge color="secondary">
                <Icon className="fas fa-camera" />
              </Badge>
              <Typography className={classes.label}>Upload</Typography>
            </IconButton>
            {/* Favorites */}
            <IconButton
              onClick={() => history.push('/favorites')}
              aria-label="show favorites"
              color="inherit"
              style={{ borderRadius: '10px' }}
            >
              <Badge color="secondary">
                <Icon
                  style={{ overflow: 'visible' }}
                  className="fas fa-heart"
                />
              </Badge>
              <Typography className={classes.label}>Favorites</Typography>
            </IconButton>
            {/* Account */}
            <IconButton
              onClick={() => history.push('/account')}
              aria-label="account of current user"
              color="inherit"
              style={{ borderRadius: '10px' }}
            >
              <Icon className="fas fa-user-circle" />
              <Typography className={classes.label}>Account</Typography>
            </IconButton>
            {/* Sign Out */}
            <IconButton
              edge="end"
              onClick={() => auth.signOut()}
              aria-label="sign out"
              color="inherit"
              style={{ borderRadius: '10px' }}
            >
              <Icon className="fas fa-sign-out-alt" />
              <Typography className={classes.label}>Sign Out</Typography>
            </IconButton>
          </div>
          {/* Mobile Hamburger */}
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <Icon className="fas fa-bars" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default withRouter(Navbar);
