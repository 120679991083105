import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { auth, createUserProfileDocument } from './firebase';

import SignInAndSignUp from './pages/SignInAndSignUp';
import Upload from './pages/Upload';
import Favorites from './pages/Favorites';
import Account from './pages/Account';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LandingPage from './pages/LandingPage';

import { useAuthState } from 'react-firebase-hooks/auth';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { FilterProvider } from './contexts/FilterContext.js';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#58a5ef',
      main: '#0277bc',
      dark: '#004c8b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e35183',
      main: '#ad1457',
      dark: '#78002e',
      contrastText: '#fff',
    },
  },
});

function App() {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    const createUser = async (user) => {
      if (!loading && user) await createUserProfileDocument(user);
    };

    createUser(user);
  });

  //If our user object is still loading, then return nothing.
  //This is to prevent the user from having to see the screen "refresh".
  if (loading) {
    return <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <FilterProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              {user ? <Redirect to="/upload" /> : <LandingPage />}
            </Route>
            <Route exact path="/signin">
              {user ? <Redirect to="/upload" /> : <SignInAndSignUp />}
            </Route>
            <Route exact path="/upload">
              {user ? <Upload /> : <Redirect to="/signin" />}
            </Route>
            <Route exact path="/favorites">
              {user ? <Favorites /> : <Redirect to="/signin" />}
            </Route>
            <Route exact path="/account">
              {user ? <Account /> : <Redirect to="/signin" />}
            </Route>
            <Route exact path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
            <Route path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </FilterProvider>
    </ThemeProvider>
  );
}

export default App;
