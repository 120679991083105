import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Card,
  CardMedia,
  CardHeader,
  Button,
  Icon,
  CardContent,
  Typography,
  CardActions,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import {
  addRecipeToFavorites,
  isRecipeInFavorites,
  removeRecipeFromFavorites,
} from '../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    margin: '10px 0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    transition: '1s',
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
}));

const RecipeCard = ({ recipe }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [user, loading] = useAuthState(auth);
  const [isFavorited, setIsFavorited] = React.useState(false);

  React.useEffect(() => {
    const checkIfFavorited = async () => {
      const favorited = await isRecipeInFavorites(user, recipe.uri);
      if (favorited) {
        setIsFavorited(true);
      } else {
        setIsFavorited(false);
      }
    };
    checkIfFavorited();
  }, [user, recipe.uri, isFavorited]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        subheaderTypographyProps={{ color: 'textPrimary' }}
        align="center"
        subheader={recipe.label}
      />
      <CardMedia className={classes.media} image={recipe.image} />
      <CardContent>
        <Typography variant="body2" color="textPrimary" component="p">
          Calories / Serving: {Math.floor(recipe.calories / recipe.yield)}{' '}
          {recipe.dietLabels.length ? (
            <>
              Dietary Label{recipe.dietLabels.length >= 2 ? <>s</> : null}:{' '}
              {recipe.dietLabels.join(', ')}
            </>
          ) : null}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton
          style={{ borderRadius: '10px' }}
          onClick={() => {
            setIsFavorited(!isFavorited);
            if (!isFavorited) {
              addRecipeToFavorites(user, recipe);
            } else {
              removeRecipeFromFavorites(user, recipe.uri);
            }
          }}
          color={isFavorited ? 'secondary' : 'default'}
        >
          <Icon style={{ overflow: 'visible' }} className="fas fa-heart" />
          <Typography style={{ marginLeft: '10px' }} variant="button">
            {isFavorited ? 'Unfavorite' : 'Favorite'}
          </Typography>
        </IconButton>
        <IconButton
          style={{ borderRadius: '10px' }}
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <Icon
            className={`fas ${expanded ? 'fa-chevron-up' : 'fa-chevron-down'}`}
          />
          <Typography style={{ marginLeft: '10px' }} variant="button">
            {!expanded ? 'Expand' : 'Collapse'}
          </Typography>
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ul>
            {recipe.ingredientLines.map((ingredientLine) => (
              <li key={uuidv4()}>{ingredientLine}</li>
            ))}
          </ul>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default RecipeCard;
