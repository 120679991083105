import React, { useContext } from 'react';
import { Typography, Box, Paper, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cat } from 'react-kawaii';
import { useTheme } from '@material-ui/core/styles';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getAllRecipesFromFavorites } from '../firebase';

import RecipeCard from '../components/RecipeCard';
import PageLayout from '../components/PageLayout';

import { FilterContext } from '../contexts/FilterContext';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '30px',
  },
  cat: {
    position: 'absolute',
    bottom: '59px',
    marginTop: '100px',
    left: 'calc(50vw - 36px)',
  },
  cardList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    marginBottom: '100px',
  },
  loadingCircle: {
    marginTop: '2rem',
    textAlign: 'center',
  },
}));

const Favorites = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [recipes, setRecipes] = React.useState(null);
  const [user, loading] = useAuthState(auth);

  const { filter, changeFilter } = useContext(FilterContext);

  React.useEffect(() => {
    const getRecipes = async () => {
      setRecipes(await getAllRecipesFromFavorites(user));
    };
    getRecipes();
  }, [user]);

  let filteredRecipes;
  if (recipes && recipes.length) {
    filteredRecipes = recipes.filter((recipe) =>
      recipe.label.toLowerCase().includes(filter.toLowerCase())
    );
  }

  return (
    <PageLayout>
      <Paper
        elevation={0}
        style={{
          paddingTop: '2rem',
          paddingBottom: '0.4rem',
          marginBottom: '1rem',
        }}
      >
        <Typography align="center" className={classes.title} variant="h3">
          Favorites
        </Typography>
      </Paper>

      {!recipes ? (
        <Box className={classes.loadingCircle}>
          <CircularProgress />
        </Box>
      ) : recipes.length === 0 ? (
        <Typography align="center">No recipes favorited.</Typography>
      ) : (
        <div className={classes.cardList}>
          {filteredRecipes.map((recipe) => (
            <RecipeCard key={recipe.uri} recipe={recipe} />
          ))}
        </div>
      )}

      <Box className={classes.cat}>
        <Cat size={100} mood="lovestruck" color={theme.palette.primary.light} />
      </Box>
    </PageLayout>
  );
};

export default Favorites;
