import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyD6HWCfRQKYxks-sAffxmQzFc1RVjOg5-c',
  authDomain: 'dishify-9565a.firebaseapp.com',
  databaseURL: 'https://dishify-9565a.firebaseio.com',
  projectId: 'dishify-9565a',
  storageBucket: 'dishify-9565a.appspot.com',
  messagingSenderId: '371724155605',
  appId: '1:371724155605:web:dadbe77be35f5f0d71405c',
  measurementId: 'G-D8LSVZRSEC',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account',
});
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();

export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);
export const signInWithFacebook = () => auth.signInWithPopup(facebookProvider);
export const signInWithTwitter = () => auth.signInWithPopup(twitterProvider);

export const getAllRecipesFromFavorites = async (user) => {
  const querySnapshot = await db
    .collection(`users/${user.uid}/favoriteRecipes`)
    .get();

  const tempArr = [];
  for (let doc of querySnapshot.docs) {
    tempArr.push(doc.data());
  }
  return tempArr;
};

export const addRecipeToFavorites = async (user, recipeObj) => {
  if (!user || !recipeObj) return;

  const recipesRef = db.collection(`users/${user.uid}/favoriteRecipes`);
  recipesRef.doc(recipeObj.uri.split('_')[1]).set(recipeObj);
};

export const removeRecipeFromFavorites = async (user, recipeUri) => {
  if (!user || !recipeUri) return;

  await db
    .doc(`users/${user.uid}/favoriteRecipes/${recipeUri.split('_')[1]}`)
    .delete();
};

export const isRecipeInFavorites = async (user, recipeUri) => {
  if (!user || !recipeUri) return false;

  const snapShot = await db
    .doc(`users/${user.uid}/favoriteRecipes/${recipeUri.split('_')[1]}`)
    .get();

  if (snapShot.exists) return true;
  return false;
};

export const createUserProfileDocument = async (user, additionalData) => {
  //Return if user is null
  if (!user) return;

  const userRef = db.doc(`users/${user.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = user;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (err) {}
  }
  return userRef;
};

export const deleteUserProfileDocument = async (user) => {
  db.doc(`users/${user.uid}`).delete();
  user.delete();
};

export default firebase;
