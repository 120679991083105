import React from 'react';
import { Box, Typography, Button, Icon } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
    backgroundImage:
      'linear-gradient(to right top, #ff5891, #f161b4, #d870d3, #b481ec, #8490fb, #6c98ff, #529fff, #31a5ff, #43a9ff, #51adff, #5db1ff, #68b5ff)',
  },
  contentContainer: {
    display: 'flex',
    alignContent: 'center',
    marginTop: '4rem',
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '30px',
  },
  content: {
    marginTop: '6rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '2rem',
  },
  text: {
    color: 'white',
    fontWeight: '700',
  },
  btnContainer: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'center',
  },
  iconContainer: {
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
    fontSize: '4rem',
    margin: '0 1rem',
  },
}));

const LandingPage = ({ history }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography align="center" className={classes.text} variant="h2">
          Dishify
        </Typography>
        <Typography align="center" className={classes.text} variant="h4">
          Upload photos of dishes, get related recipes.
        </Typography>

        <Box className={classes.iconContainer}>
          <Icon className={`${classes.icon} fas fa-camera`} />
          <Icon className={`${classes.icon} fas fa-long-arrow-alt-right`} />
          <Icon className={`${classes.icon} fas fa-utensils`} />
        </Box>
        <Box className={classes.btnContainer}>
          <Button
            onClick={() => history.push('/signin')}
            color="secondary"
            style={{ fontSize: '1.5rem' }}
            variant="contained"
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(LandingPage);
